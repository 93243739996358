<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DE REGISTRO DE ACCIDENTES E INCIDENTES:
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listAccidentesEIncidentes.length}}</span>
                                <br>
                                <span class="text-muted">Accidentes e Incidentes Registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestión de Accidentes e Incidentes</span>
                        
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <!-- <b-button block variant="custom" @click="modalRegistrarIncidente = true">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo registro</span>
                                </b-button> -->
                                <b-button block variant="custom" :to="{name: 'Gestión Accidente e Incidentes'}">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo registro</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Listado de Accidentes e Incidentes Registrados </span>
                        <b-button v-if="listAccidentesEIncidentes.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listAccidentesEIncidentes" :fields="camposAccidentesIncidentes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(guiaPrincipal)="data">
                                        <template v-if="listUsuarios.find(x => x.idGuia == data.item.idGuia) == null">
                                            {{''}}
                                        </template>
                                        <template v-else>
                                            {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}}
                                        </template>
                                        <!-- {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}} -->
                                    </template>
                                    <template v-slot:cell(fecha)="data">
                                        <!-- {{data.item.fecha}} -->
                                        {{formatearFecha(data.item.fecha,'DD-MM-YYYY')}}
                                    </template>
                                    <template v-slot:cell(diaviaje)="data">
                                        {{data.item.diaViaje}}
                                    </template>
                                    <template v-slot:cell(cantPasajeros)="data">
                                        {{data.item.listPasajerosVinculados.length}}
                                    </template>
                                    <template v-slot:cell(cantPersonales)="data">
                                        {{data.item.listPersonalesVinculados.length}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Gestión Accidente e Incidentes', params: {id: param.item.id}}" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarAccidenteIncidentes(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbAccidenteIncidentes = firebase.firestore().collection('accidenteIncidentes');

import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            listAccidentesEIncidentes: [],
            listUsuarios: [],
            disable: false,

            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            filasTotales: 1,
            paginaActual: 1,

            camposAccidentesIncidentes: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "guiaPrincipal",
                    label: "Guia Principal",
                    class: "text-center",
                },
                {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "diaviaje",
                    label: "Dia de Viaje",
                    class: "text-center",
                },
                {
                    key: "cantPasajeros",
                    label: "Cant. Pasajeros",
                    class: "text-center",
                },
                {
                    key: "cantPersonales",
                    label: "Cant. Personales",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listAccidentesEIncidentes.map(data => {
                return {
                    A: valUpper(this.listUsuarios && this.listUsuarios !== undefined ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia).length > 0 ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia)[0].nombre : '' : ''),
                    B: valDate(data['fecha']),
                    C: valUpper(data['diaViaje']),
                    D: data['listPasajerosVinculados'] && data['listPasajerosVinculados'] !== undefined ? data['listPasajerosVinculados'].length : 0,
                    E: data['listPasajerosVinculados'] && data['listPasajerosVinculados'] !== undefined && data['listPasajerosVinculados'].length > 0 ? data['listPasajerosVinculados'].map(elx => {
                        return `NOMBRE: ${valUpper(elx['nombre'])} - APELLIDO: ${valUpper(elx['apellido'])} - NRO DE DOCUMENTO: ${valUpper(elx['nrodoc'])}`
                    }).join('\n') : '',
                    F: data['listPersonalesVinculados'] && data['listPersonalesVinculados'] !== undefined ? data['listPersonalesVinculados'].length : 0,
                    G: data['listPersonalesVinculados'] && data['listPersonalesVinculados'] !== undefined && data['listPersonalesVinculados'].length > 0 ? data['listPersonalesVinculados'].map(per => {
                        return `NOMBRE: ${valUpper(per['nombre'])} - APELLIDO: ${valUpper(per['apellido'])} - NRO DE DOCUMENTO: ${valUpper(per['nrodoc'])}`
                    }).join('\n') : '',
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 15, //B
                },
                {
                    wch: 20, //C
                },
                {
                    wch: 10, //D
                },
                {
                    wch: 70, //E
                },
                {
                    wch: 10, //F
                },
                {
                    wch: 70, //G
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'ACCIDENTES E INCIDENTES',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NOMBRE DEL GUÍA',
                },
                {
                    f: 'B4',
                    v: 'FECHA',
                },
                {
                    f: 'C4',
                    v: 'DIA DE VIAJE',
                },
                {
                    f: 'D4',
                    v: 'CANT. DE PASAJEROS',
                },
                {
                    f: 'E4',
                    v: 'LISTADO DE PASAJEROS',
                },
                {
                    f: 'F4',
                    v: 'CANT. DE PERSONALES',
                },
                {
                    f: 'G4',
                    v: 'LISTADO DE PERSONALES',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'ACCIDENTES E INCIDENTES');
            XLSX.writeFile(wb, `ACCIDENTES E INCIDENTES ${now}.xlsx`);
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        formatearFecha(fecha,formato){
            return moment(fecha).format(formato);
        },

        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });

        },
        listarAccidenteIncidentes() {
            let me = this;

            dbAccidenteIncidentes
            .where("estado", "==",2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot)=> {
                me.listAccidentesEIncidentes = [];
                querySnapshot.forEach((doc) => {
                    me.listAccidentesEIncidentes.push({
                        id: doc.id,
                        detalle: doc.data().detalle,
                        diaViaje: doc.data().diaViaje,
                        estado: doc.data().estado,
                        fecha: doc.data().fecha,
                        idCliente: doc.data().idCliente,
                        idGuia: doc.data().idGuia,
                        listPasajerosVinculados: doc.data().listPasajerosVinculados,
                        listPersonalesVinculados: doc.data().listPersonalesVinculados,
                    });
                });
                me.listAccidentesEIncidentes.sort((a,b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        eliminarAccidenteIncidentes(param) {
            let me = this;
            
            me.$swal.fire({
                title: '¿Estas seguro de eliminar?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbAccidenteIncidentes.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        me.disable = false;
                    })
                    .catch((error) => {
                        me.disable = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title,
            });
        },
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarAccidenteIncidentes();
        }
    },
}
</script>